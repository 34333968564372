export default {
    "url":"",
    "edoctusUrl":"",
    "version":19,
    "edocutusDocumentTypeId":6,
    "edocutusDatabase":"NG UAT",
    "secretPhrase": "2348hsulhdfhgulewnrflksfd",
    "reportUrl":"https://app.powerbigov.us/groups/me/apps/00a60a1b-0b31-4d9f-97a0-829a33df0b81/reports/5e06b675-96ad-4538-aa37-4d8c0453b3e7/ReportSection?ctid=5ec1d8f0-cb62-4000-b327-8e63b0547048",
    "VehSrchStaticText":"No Vehicle Search History found for the entered Reimbursement Period. User is ineligible for Mileage Expense Reimbursement.",
    "logoutUrl":"https://tripsuat.dhs.arkansas.gov/",
	"cvtUrl":"https://ladro.dhhs.arkgov.net/ofa/CVT/",
	"CheckMFA": "YES"
}